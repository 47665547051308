import React from "react"
import styled from '@emotion/styled'
import logo from "./ongress-logo.jpeg"
import Layout from "../components/layout"

const Container = styled.div`
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  height: min-height: 60px;
  width: 163px;
`
export default () => (
  <Layout>
    <Container>
      <Logo src={logo} alt="Logo" />
    </Container>
  </Layout>
)
