import React from "react"
import Helmet from "react-helmet"
import styled from '@emotion/styled'
import { Normalize } from 'styled-normalize'
import { Global } from 'styled-components'
import favicon from '../static/favicon.png';

// createGlobalStyle`
//   body {
//     color: ${props => (props.whiteColor ? 'white' : 'black')};
//     background: red;
//   }
// `
const Container = styled.div`
  height:100vh;
  background-size: cover;
`

      // link={[ { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` } ]}
export default ({ location, children, props }) => (
  <Container>
    <Normalize />
    <Helmet defaultTitle={`ongress`} titleTemplate={`%s | ongress`}>
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="ongress" />
      <meta name="docsearch:version" content="2.0" />
      <link
        rel="canonical"
        href={`https://rodein.de`}
      />
      <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      <html lang="en" />
    </Helmet>
    {children}
  </Container>
)
